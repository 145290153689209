<template>
  <section class="info-container">
    <section class="info-item-wrap">
      <p class="info-title">在线广播</p>
    </section>

    <section class="info-item-wrap m-t-18">
      <a-row class="m-t-18">
        <a-button @click="addVisible = true" class="f-right" type="primary">添加</a-button>
      </a-row>
      <p></p>
      <section class="list-table-container">
        <a-table size="small" :columns="columns" :pagination="false" :data-source="listMap">
          <a slot="action" slot-scope="text">
            <!-- <a class="m-r-10" @click="showAudioModal(list)">试听</a> -->
            <a class="m-r-10" @click="check(text.id,text.name)">查看</a>
            <a-popconfirm title="确定删除该区域?" @confirm="deleteItem(text.id)">删除</a-popconfirm>
          </a>
        </a-table>
        <section class="pagination-container">
          <a-pagination
            show-quick-jumper
            show-size-changer
            :current="data.current"
            :total="data.total"
            :show-total="(total) => `共${total}条`"
            @change="onCurrentChange"
            @showSizeChange="onShowSizeChange"
          />
        </section>
      </section>
    </section>

    <a-modal
      centered
      width="660px"
      class="modal-container"
      v-model="addVisible"
      :footer="false"
      :destroyOnClose="true"
      @cancel="addVisible = false"
    >
      <a-form :form="form" layout="inline" class="m-t-18">
        <a-form-item label="区域名称">
          <a-input
            placeholder="请输入"
            class="form-input"
            v-decorator="[
              'name',
              { rules: [{ required: true, message: '区域名称不能为空' }] },
            ]"
          ></a-input>
        </a-form-item>
        <a-row class="form-btn-group">
          <a-button type="primary" @click="saveName">保存</a-button>
          <a-button class="btn-cancel" @click="addVisible = false">取消</a-button>
        </a-row>
      </a-form>
    </a-modal>

    <a-modal
      centered
      width="660px"
      class="modal-container"
      v-model="showVisible"
      :footer="false"
      :destroyOnClose="true"
      @cancel="showVisible = false"
    >
      <p>区域名称：{{showName}}</p>
      <section class="list-table-container">
        <a-table size="small" :columns="playColumns" :data-source="playLists" :pagination="false"></a-table>
      </section>
    </a-modal>
  </section>
</template>

<script>
const columns = [

  { title: '序号', dataIndex: 'ind', align: 'center', key: 'ind' },
  { title: '区域名称', dataIndex: 'name', align: 'center', key: 'name' },
  { title: '更新时间', dataIndex: 'updateTime', align: 'center', key: 'updateTime' },
  { title: '操作', key: 'logImage', scopedSlots: { customRender: "action" }, align: 'center' }
]
const playColumns = [

  { title: '序号', dataIndex: 'ind', align: 'center', key: 'ind' },
  { title: '广播设备名称', dataIndex: 'deviceName', align: 'center', key: 'deviceName' },
  { title: '绑定时间', dataIndex: 'createTime', align: 'center', key: 'createTime' },

]
// import moment from 'moment'
export default {
  data() {
    return {
      columns,
      playColumns,
      total: 0,
      listMap: [],
      playLists: [],
      data: {
        current: 1,
        size: 10,
      },
      addVisible: false,
      showVisible: false,
      showName: '',//用于展示时的名称
    }
  },
  components: {},
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  created() {
    this.getBaseConfig()
  },
  methods: {
    //查询基本配置列表
    getBaseConfig() {
      this.$api.getBaseConfig({
        current: this.data.current,
        size: this.data.size
      }).then(res => {
        console.log('config', res);
        if (res.code === 200) {
          this.listMap = (res.data && res.data.records) || []
          this.listMap.forEach((item, ind) => {
            item.ind = ind + 1
          })
          this.data.total = (res.data && res.data.total) || 0
        }
      })
    },
    saveName() {
      this.form.validateFields(async (err, formData) => {
        if (!err) {
          let params = { ...formData };
          params.name = this.clearSpace(params.name)
          console.log('params', params);
          await this.$api.addBaseConfig(params).then(res => {
            if (res.code == 200) {

              this.$message.success('添加成功');
              this.addVisible = false;
              this.data.current = 1;
              this.data.size = 10;
              this.getBaseConfig();
            }
            else {
              this.$message.error(res.msg)
            }
          })
          //   this.$router.push("/accident/expressNews");
        }
      }).catch(err => {
        this.$message.error(err)
      });
    },
    check(id, name) {
      this.$api.getByAreaId({ id: id }).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.playLists = res.data ? res.data : []
          this.playLists.forEach((item, ind) => {
            item.ind = ind + 1
          })
          this.showName = name;
          this.showVisible = true;

        }
      })
    },
    deleteItem(id) {

      let params = {
        id: id
      }
      this.$api.deleteBaseConfig(params)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message.success("删除成功");

            this.data.current = 1;
            this.data.size = 10;
            this.getBaseConfig();
          }
          else {
            this.$message.error('删除失败')
          }
        })
        .catch(() => {
          //   this.$message.error(err);
        });
    },
    //分页相关

    onCurrentChange(page, pageSize) {
      this.$set(this.data, "current", page);
      this.$set(this.data, "size", pageSize);
      this.getBaseConfig();
    },
    onShowSizeChange(current, size) {
      this.$set(this.data, "current", current);
      this.$set(this.data, "size", size);
      this.getBaseConfig();
    },
    clearSpace(str) {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    }
  },
  beforeDestroy() {
  },
}
</script>
<style lang="scss" scoped>
// 页面分页
.pagination-container {
  position: absolute;
  bottom: 0;
  height: 60px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  //修复分页样式错误
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    svg {
      height: 32px;
    }
  }
}
</style>